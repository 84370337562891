import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CustomCharacterText from '../Text/CharacterText';

const Banner = styled.div<{ zoom: number; url: any }>`
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  width: 190mm;
  height: 270mm;
  ${props => props.zoom !== 1 && `transform-origin: top left;`};
  transform: ${(props) => (props.url ? 'scale(1)' : 'scale(' + props.zoom + ')')};
  background-color: transparent;
  page-break-after: always;
`;

const BannerImage = styled.div<{ show: any; src: any }>`
  width: 100%;
  height: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  visibility: ${(props) => (props.show ? 'hidden' : 'visible')};
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  z-index: -1;
`;

const BannerResultContainer = styled.div<{ url: any }>`
  width: 100%;
  padding: 10px;
  scrollbar-width: thin;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  border: 1px solid #ccc;
  position: relative;
  background-color: ${(props) => (props.url ? '#2a2a2e' : '#fff')};
  max-height: ${(props) => (props.url ? 'auto' : '600px')};
  overflow-y: ${(props) => (props.url ? 'auto' : 'scroll')};
  overflow-x: scroll;
`;



const Watermark = styled.div`
  position: absolute;
  top: 92%;
  left: 85%;
  width: 100px;
  height: 100px;
  z-index: 9999;
  background-image: url(${require('../../assets/watermark.jpg')});
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.5;
`;

const Sticker = styled.img<{ textconfig: any; src: any }>`
  position: absolute;
  top: ${(props) => props.textconfig.StickerTop}%;
  left: ${(props) => props.textconfig.StickerLeft}%;
  transform: translate(-50%, -50%) scale(${(props) => props.textconfig.StickerSize / 10});
  z-index: 9999;
`;

export default function BannerResult(props: any) {
  const { characters, bannerimage, textconfig, url, stickerimage, targetRef, zoom } = props;
  // const targetRef = React.useRef(null);
  // const [loadingDownload, setLoadingDownload] = useState(false);


  const [characterlist, setcharacterlist] = useState([] as any[any] | any[any][any]);

  useEffect(() => {
    const characterlist = characters.replace(/\s/g, '').split('');
    setcharacterlist(characterlist);

    // if (url && characterlist.length > 0) {
    //   setTimeout(() => {
    //     if (loadingDownload) return;
    //     downloadPdf();
    //   }, 4000);
    // }
    //@ts-ignore
  }, [characters]);

  // const downloadPdf = async () => {
  //   setLoadingDownload(true);
  //   generatePDF(targetRef, {
  //     method: 'save',
  //     filename: 'eduzabawy-banner.pdf',
  //     overrides: {
  //       pdf: {
  //         compress: true,
  //         putOnlyUsedFonts: true,
  //         format: 'a4',
  //         unit: 'px',
  //       },
  //       canvas: {
  //         logging: true,
  //       },
  //     },
  //   }).then(() => {
  //     console.log('Successfully downloaded PDF document!');
  //     setTimeout(() => {
  //       window.close();
  //     }, 1000);
  //   });
  // };

  return (
      <BannerResultContainer url={+url}>
        {characterlist.length === 0 && (
          <Banner zoom={zoom} url={+url}>
            <BannerImage src={bannerimage} show={+textconfig.mask} />
          </Banner>
        )}


        <div
          // ref={targetRef}
          style={{

          }}
          id="scrollable-content"
          ref={targetRef}
        >
          {characterlist.map(
            (character: any, index: any) =>
              bannerimage && (
                <Banner id='pdf-section' zoom={zoom} url={+url} key={index}>
                  <BannerImage src={bannerimage} show={+textconfig.mask} />
                  <CustomCharacterText text={character} textconfig={textconfig} />
                  <Sticker textconfig={textconfig} src={stickerimage} />
                  <Watermark />
                </Banner>
              )
          )}
        </div>

      </BannerResultContainer>
      
      
  );
}
