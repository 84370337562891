import React, { useState } from 'react';

import styled from 'styled-components';
import { MuiColorInput } from 'mui-color-input';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FontSelect from '../SubComponents/FontSelect';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const HiddenUploadInput = styled.input.attrs({ type: 'file', id: 'fileInput', accept: 'image/*' })`
  display: none;
`;

const RemoveSticker = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'flex' : 'none')};
  width: 30px !important;
  min-width: 30px !important;
  height: 36px;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  font-size: 22px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
  background-color: #1976d2;
`;

const HiddenUploadInputSticker = styled.input.attrs({
  type: 'file',
  id: 'stickerInput',
  accept: 'image/*',
})`
  display: none;
`;

const Container = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 20px;
  justify-items: center;
  align-items: center;

  width: 100%;

  & > * {
    width: 100px;
    min-width: 100px;
  }
`;

function CharacterTextStyleSelector(props: any) {
  const { settextconfig, uploadSelect, uploadSelectSticker } = props;
  const [stickerSet, setStickerSet] = useState(false);
  const [textconfig, settextconfigState] = useState({
    fontFamily: 'Default',
    color: 'rgba(255, 255, 255, 1)',
    fontSize: 30,
    fontStyle: 'Default',
    textOutlineWidth: 1,
    textOutlineColor: 'rgba(0, 0, 0, 1)',
    mask: false,
    top: 0,
    StickerTop: 50,
    StickerLeft: 50,
    StickerSize: 5,
  });

  const handleChange = (e: any, name: any) => {
    if (name === 'textOutlineWidth' || name === 'fontSize') {
      const re = /^[0-9\b]+$/;
      if (!re.test(e.target.value)) {
        return;
      }
    }

    const { value } = e.target;
    settextconfigState((prev) => ({ ...prev, [name]: value }));
    settextconfig((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleChangeColor = (newValue: any, name: any) => {
    settextconfigState((prev) => ({ ...prev, [name]: newValue }));
    settextconfig((prev: any) => ({ ...prev, [name]: newValue }));
  };

  return (
    <Container>
      <FontSelect handlechange={handleChange} />

      <MuiColorInput
        value={textconfig.color}
        onChange={(newValue) => handleChangeColor(newValue, 'color')}
        label="Kolor czcionki"
      />

      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => handleChange(e, 'fontSize')}
        variant="outlined"
        label="Rozmiar cziocnki"
        value={textconfig.fontSize}
      />

      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => handleChange(e, 'textOutlineWidth')}
        variant="outlined"
        value={textconfig.textOutlineWidth}
        label="Kontur: rozmiar"
      />

      <MuiColorInput
        value={textconfig.textOutlineColor}
        onChange={(newValue) => handleChangeColor(newValue, 'textOutlineColor')}
        label="Kontur: kolor"
      />

      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => handleChange(e, 'top')}
        variant="outlined"
        value={textconfig.top}
        label="Pozycja od góry"
      />

      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => handleChange(e, 'StickerSize')}
        variant="outlined"
        value={textconfig.StickerSize}
        label="Rozmiar dekoru"
      />

      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => handleChange(e, 'StickerTop')}
        variant="outlined"
        value={textconfig.StickerTop}
        label="Dekor: od góry"
      />

      <TextField
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e: any) => handleChange(e, 'StickerLeft')}
        variant="outlined"
        value={textconfig.StickerLeft}
        label="Dekor: od lewej"
      />

      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          const fileInput = document.getElementById('stickerInput');
          fileInput?.click();

          fileInput?.addEventListener('change', (e) => {
            const target = e.target as HTMLInputElement;
            const file = target.files?.[0];
            if (file) {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64String = reader.result;
                uploadSelect(base64String);
              };
              reader.readAsDataURL(file);
            }
          });
        }}
      >
        Tło
      </Button>
      <HiddenUploadInput />

      <Button
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
        onClick={() => {
          const fileInput = document.getElementById('fileInput');
          fileInput?.click();

          fileInput?.addEventListener('change', (e) => {
            const target = e.target as HTMLInputElement;
            const file = target.files?.[0];

            if (file) {
              const reader = new FileReader();
              reader.onloadend = () => {
                const base64String = reader.result;
                uploadSelectSticker(base64String);
                setStickerSet(true);
              };
              reader.readAsDataURL(file);
            }
          });
        }}
      >
        Dekor
      </Button>
      <HiddenUploadInputSticker />
      <RemoveSticker
        visible={stickerSet}
        onClick={() => {
          setStickerSet(false);
          uploadSelectSticker('');
        }}
      >
        X
      </RemoveSticker>
    </Container>
  );
}

export default CharacterTextStyleSelector;
