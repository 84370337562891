import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import CharacterTextStyleSelector from './Text/CharacterTextStyleSelector';
import TextField from '@mui/material/TextField';
import BannerResult from './Result/BannerResult';
import Banners from './BannerImage/Banners';
import { DEFAULT_TEXT_CONFIG } from '../utils/Constants';
import DropdownTabComponent from './SubComponents/DropdownTabComponent';
import axios from 'axios';
import generatePDF, { Margin, Resolution } from 'react-to-pdf';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

const CharacterBannerMain = styled.div`
  text-align: center;
  margin: 0 auto;
`;

const DownlopadButton = styled.button`
  background-color: #fff;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #000;
  border-radius: 5px;
  padding: 10px 30px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;

  color: ${(props) => (props.disable ? '#ccc' : '#000')};
  border: ${(props) => (props.disable ? '1px solid #ccc' : '1px solid #000')};
  cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};

  &:disabled {
    background-color: #ccc;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    padding: 10px 30px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    cursor: not-allowed;
  }
`;

const CharacterBannerContainer = styled.div`
  text-align: center;
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
`;

const CharacterInput = styled(TextField)`
  width: 100%;
  margin-right: 10px; /* Add margin to separate the button and input */
`;

const CharacterInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch; /* Align items in a way that they stretch to fill the container */
  margin-bottom: 10px;
  gap: 10px;
`;

const Spinner = styled.div`
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 15px;
  display: ${(props) => (props.display ? 'block' : 'none')};
  height: 15px;
  animation: spin 2s linear infinite;
  margin-right: 10px;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;

const BannerContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
  // flex-wrap: wrap;
  width: 100%;
`;

function CharacterBanner() {
  const [character, setCharacter] = useState('');
  const [bannerSelect, setBannerSelect] = useState('eleganckie');
  const [bannerImage, setbannerimage] = useState('');
  const [stickerimage, setstickerimage] = useState('');
  const [textconfig, settextconfig] = useState(DEFAULT_TEXT_CONFIG);
  const [loadingDownload, setLoadingDownload] = useState(false);

  const [zoom, setZoom] = useState(0.65);

  const handleCharacterChange = (event) => {
    setCharacter(event.target.value);
  };
  const targetRef = useRef(null);

  async function download() {
    setLoadingDownload(true);
    setZoom(1);
    const options = {
      margin: Margin.SMALL,
      filename: 'eduzabawy-banner.pdf',
      image: { type: 'png' },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['css', 'legacy'] }
    };
    const element = document.getElementById('scrollable-content');
    const characterlist = character.replace(/\s/g, '');
    
    let chuncks = []

    for (let i = 0; i < characterlist.length;) {
      chuncks.push(characterlist.slice(i, i + 20))
      i = i + 20
    }
    const time_int=new Date().getTime();
    for (let i = 0; i < chuncks.length; i++) {
      setCharacter(chuncks[i])
      await new Promise(resolve => setTimeout(resolve, 500));
      options.filename = `eduzabawy-banner-${time_int}-part-${i}.pdf`;
      await html2pdf().set(options).from(element).save();

    }
    setCharacter(characterlist)
    setZoom(0.65);
    setLoadingDownload(false);
  }


  return (
    <CharacterBannerMain>
      <CharacterBannerContainer>
        <CharacterInputContainer>
          <CharacterInput
            type="text"
            placeholder="Tu napis"
            value={character}
            onChange={handleCharacterChange}
          />
          <DownlopadButton
            disable={loadingDownload}
            onClick={download}
            disabled={character.length === 0 || bannerImage.length === 0}
          >
            <Spinner display={!!loadingDownload} />
            Pobierz
          </DownlopadButton>
        </CharacterInputContainer>

        <CharacterTextStyleSelector
          settextconfig={settextconfig}
          uploadSelect={(uploadSelect) => setbannerimage(uploadSelect)}
          uploadSelectSticker={(uploadSelect) => setstickerimage(uploadSelect)}
        />
        <DropdownTabComponent
          onBannerSelect={setBannerSelect}
          uploadSelect={(uploadSelect) => setbannerimage(uploadSelect)}
        />
        <BannerContainer>
          <Banners
            bannerimage={bannerImage}
            setbannerimage={setbannerimage}
            bannerSelect={bannerSelect}
          />

          <BannerResult
            characters={character}
            bannerimage={bannerImage}
            textconfig={textconfig}
            stickerimage={stickerimage}
            targetRef={targetRef}
            zoom={zoom}
          />
        </BannerContainer>
      </CharacterBannerContainer>
    </CharacterBannerMain>
  );
}

export default CharacterBanner;
